import { Application } from '@hotwired/stimulus'

import '../styles/tailwind.compiled.css'
import '../styles/fonts.css'

import MenuController from './main/menu_controller'
import CarouselController from './main/carousel_controller'
import PersonController from './main/person_controller'

window.Stimulus = Application.start()
window.Stimulus.register('menu', MenuController)
window.Stimulus.register('carousel', CarouselController)
window.Stimulus.register('person', PersonController)
