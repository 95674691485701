/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Controller } from '@hotwired/stimulus'

class MenuController extends Controller<HTMLDivElement> {
  static targets = ['button', 'overlay', 'menu', 'iconBars', 'iconX']
  #active = false

  declare readonly buttonTarget: HTMLButtonElement
  declare readonly overlayTarget: HTMLDivElement
  declare readonly menuTarget: HTMLDivElement
  declare readonly iconBarsTarget: HTMLDivElement
  declare readonly iconXTarget: HTMLDivElement

  toggle() {
    if (this.#active) {
      this.#active = false
      this.close()
    } else {
      this.#active = true
      this.open()
    }
  }

  outside(event) {
    if (
      !(
        this.buttonTarget === event.target ||
        this.buttonTarget.contains(event.target) ||
        this.menuTarget === event.target ||
        this.menuTarget.contains(event.target)
      )
    ) {
      this.close()
    }
  }

  open() {
    this.overlayTarget.classList.add('!visible', '!opacity-100')
    this.iconBarsTarget.classList.remove('!block')
    this.iconXTarget.classList.add('!block')
    this.#active = true
  }

  close() {
    this.overlayTarget.classList.remove('!visible', '!opacity-100')
    this.iconBarsTarget.classList.add('!block')
    this.iconXTarget.classList.remove('!block')
    this.#active = false
  }
}

export default MenuController
