/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Controller } from '@hotwired/stimulus'
import Swiper from 'swiper'

import { Pagination, Autoplay } from 'swiper/modules'

import 'swiper/css'

class CarouselController extends Controller<HTMLFormElement> {
  static targets = ['block']
  static values = {
    autoplay: { type: Boolean, default: false },
    interval: { type: Number, default: 5000 },
    pagination: { type: Boolean, default: false },
  }

  declare readonly blockTarget: HTMLFormElement
  declare autoplayValue: string
  declare intervalValue: number
  declare paginationValue: string

  connect() {
    new Swiper(this.blockTarget, {
      modules: [Pagination, Autoplay],
      ...(this.autoplayValue
        ? {
            autoplay: {
              delay: this.intervalValue,
              pauseOnMouseEnter: true,
              disableOnInteraction: true,
            },
          }
        : {}),
      ...(this.paginationValue ? { pagination: { el: '.swiper-pagination' } } : {}),
    })
  }
}

export default CarouselController
