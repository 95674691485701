/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Controller } from '@hotwired/stimulus'

class PersonController extends Controller<HTMLFormElement> {
  static targets = ['block', 'modal', 'content']

  declare readonly blockTarget: HTMLDivElement
  declare readonly modalTarget: HTMLDivElement
  declare readonly contentTarget: HTMLDivElement

  toggle() {
    this.modalTarget.classList.toggle('!visible')
    this.modalTarget.classList.toggle('!opacity-100')
  }

  outside(event) {
    if (this.contentTarget === event.target || this.contentTarget.contains(event.target)) return

    this.close()
  }

  open() {
    this.modalTarget.classList.add('!visible', '!opacity-100')
  }

  close() {
    this.modalTarget.classList.remove('!visible', '!opacity-100')
  }
}

export default PersonController
